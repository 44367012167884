.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 20px;
}
.wrapper-card-list {
  display: flex;
  max-width: 1000px;
  width: 100%;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.preloader-games-list {
  margin-top: 25%;
}
