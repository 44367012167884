.search__input {
  max-width: 700px;
  width: 100%;
}

.search {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
