* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.product-item {
  width: 200px;
  text-align: center;
  margin: 0 auto;
  border-bottom: 2px solid #f5f5f5;
  background: white;
  font-family: "Open Sans";
  transition: 0.3s ease-in;
  min-height: 300px;
}
.product-item:hover {
  border-bottom: 2px solid #1976d2;
  transform: scale(1.1);
}

.product-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.product-list {
  background: #fafafa;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product-list h3 {
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  margin: 0 0 10px 0;
}
.price {
  font-size: 16px;
  color: #fc5a5a;
  display: block;
  margin-bottom: 12px;
}
.button {
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  padding: 0 12px;
  background: #cccccc;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 28px;
  transition: 0.3s ease-in;
}
.product-item:hover .button {
  background: #1976d2;
}
